'use strict'

require('../helpers/polyfills')

const get_ = require('lodash/get')
const values_ = require('lodash/values')
const includes_ = require('lodash/includes')
const merge_ = require('lodash/merge')
const defaultsDeep_ = require('lodash/defaultsDeep')
const noop_ = require('lodash/noop')
const pick_ = require('lodash/pick')
const uniq_ = require('lodash/uniq')
const without_ = require('lodash/without')
const isEmpty_ = require('lodash/isEmpty')
const flatten_ = require('lodash/flatten')
const difference_ = require('lodash/difference')

const DATASET_TYPES = require('@wix/wix-data-client-common/src/datasetTypes')

const { convertFromCustomFormat } = require('@wix/cloud-elementory-protocol')
const defaultDatasetConfiguration = require('@wix/wix-data-client-common/src/dataset-configuration/defaults')
const {
  parseUrlPattern
} = require('@wix/dbsm-common/src/dynamic-pages/urlUtils')
const schemaAPICreator = require('../schemas/schemaAPI')
const getReferencedCollectionsNames = require('../schemas/getReferencedCollectionsNames')
const wixDataProxyCreator = require('../wix-data/wixDataProxy')
const { createRecordStoreService } = require('../record-store')
const createControllerFactory = require('../dataset-controller/controllerFactory')

const createDatabindingVerboseReporter = require('../verbose/databindingVerboseReporter')
const { traceCreators } = require('../logger')
const { TraceType } = require('../logger/traceType')
const {
  isEnvEditor,
  isModePreview,
  isModeLivePreview
} = require('../helpers/viewMode')
const { nullVerboseReporter } = require('../dataset-api/verbosity')
const Deferred = require('../helpers/Deferred')

const getSDK = controllerConfigs => controllerConfigs[0].wixCodeApi
const isSSRMode = controllerConfigs =>
  get_(getSDK(controllerConfigs), ['window', 'rendering', 'env']) === 'backend'
const getViewMode = controllerConfigs =>
  get_(getSDK(controllerConfigs), ['window', 'viewMode'])

const getDefaultFieldsSort = patternFields =>
  patternFields.map(field => ({ [field]: 'asc' }))

const getSortObject = sortArray =>
  sortArray.reduce(
    (accumulator, currentValue) => Object.assign(accumulator, currentValue),
    {}
  )

const extractDynamicUrlPatternFieldsValuesFromRecord = (
  dynamicUrl,
  record,
  sortFields,
  patternFields
) => {
  const sortAndPatternFields = patternFields.concat(sortFields)
  return patternFields.length ? pick_(record, sortAndPatternFields) : null
}

const getDatasetSortFields = sort =>
  flatten_(sort.map(sortItem => Object.keys(sortItem).map(key => key)))

const extractRouterPayload = (payload, parser) => {
  const { dynamicUrl, userDefinedFilter, items, totalCount, config } = payload
  const parsedItems = parser(items)
  const record = parsedItems ? parsedItems[0] : null
  const datasetSort = get_(config, 'dataset.sort', []) || []
  const patternFields =
    dynamicUrl && record ? parseUrlPattern(dynamicUrl).fields : []
  const datasetSortFields = getDatasetSortFields(datasetSort)
  const unsortedPatternFields = difference_(patternFields, datasetSortFields)
  const sort = getSortObject([
    ...datasetSort,
    ...getDefaultFieldsSort(unsortedPatternFields)
  ])
  const sortFields = [...datasetSortFields, ...unsortedPatternFields]

  const dynamicUrlPatternFieldsValues = extractDynamicUrlPatternFieldsValuesFromRecord(
    dynamicUrl,
    record,
    sortFields,
    patternFields
  )

  return {
    prefetchedData: {
      items: parsedItems,
      totalCount
    },
    dynamicPagesData: {
      dynamicUrl,
      userDefinedFilter,
      dynamicUrlPatternFieldsValues,
      sort,
      sortFields,
      patternFields
    }
  }
}

const mergeRouterDatasetConfig = (routerConfig, controllerConfig) =>
  merge_({}, controllerConfig, routerConfig)

const extractPlatformControllerAPI = ({ pageReady, exports, dispose }) => ({
  pageReady,
  exports,
  dispose
})

module.exports = ({
  wixDataCreator,
  errorReporter,
  verboseReporter: originalVerboseReporter,
  shouldVerbose,
  appLogger,
  automationsClientCreator
}) => {
  let wixDataProxy
  let wixDataSchemasProxy
  let routerPayload
  let schemaAPI
  let wixSdk
  let recordStoreCache
  let verboseReporter
  let automationsClient

  const completeControllerConfigs = controllerConfigs => {
    return controllerConfigs.map(controllerConfig => {
      const { config, type } = controllerConfig

      const mergedConfig =
        type === DATASET_TYPES.ROUTER_DATASET
          ? mergeRouterDatasetConfig(routerPayload.config, config)
          : config

      const datasetConfiguration = defaultsDeep_({}, mergedConfig, {
        dataset: defaultDatasetConfiguration
      })

      return {
        ...controllerConfig,
        config: datasetConfiguration
      }
    })
  }

  const startLoadingSchemas = (controllerConfigs, modeIsSSR) => {
    const prefetchedSchemaData = null

    if (prefetchedSchemaData) {
      return schemaAPI.loadPrefetched(prefetchedSchemaData)
    }

    const collectionIds = getMainCollectionIds(controllerConfigs)

    if (
      collectionIds.length === 1 &&
      hasAllSchemasReferencedByCollection(
        collectionIds[0],
        routerPayload.schemas
      )
    ) {
      return schemaAPI.loadPrefetched(routerPayload.schemas)
    }

    return appLogger
      .traceAsync(traceCreators.loadSchemas(), () =>
        schemaAPI.loadSchemas(collectionIds)
      )
      .catch(() => {})
  }

  const hasAllSchemasReferencedByCollection = (collectionId, schemas) => {
    if (isEmpty_(schemas)) {
      return false
    }

    const mainSchema = schemas[collectionId]
    if (isEmpty_(mainSchema)) {
      return false
    }

    const referencedCollectionIds = getReferencedCollectionsNames(mainSchema)
    return (
      isEmpty_(referencedCollectionIds) ||
      referencedCollectionIds.every(cid => !isEmpty_(schemas[cid]))
    )
  }

  const getMainCollectionIds = controllerConfigs => {
    const ids = controllerConfigs.map(controllerConfig =>
      get_(
        controllerConfig,
        [
          'config',
          'dataset',
          'collectionName' // is actually collectionId :(
        ],
        null
      )
    )
    return without_(uniq_(ids), null)
  }

  const app = {
    initAppForPage: (
      { routerReturnedData },
      _,
      _wixSdk,
      {
        bi = {},
        reportTrace = noop_,
        monitoring: { createMonitor: createRavenClient },
        fedOpsLoggerFactory,
        biLoggerFactory
      } = {}
    ) => {
      try {
        wixSdk = _wixSdk
        recordStoreCache = {}

        const modeIsSsr =
          get_(wixSdk, ['window', 'rendering', 'env']) === 'backend'
        const viewMode = get_(wixSdk, ['window', 'viewMode'])
        verboseReporter =
          shouldVerbose && isModePreview(viewMode)
            ? originalVerboseReporter
            : nullVerboseReporter

        appLogger.addSessionData(() => ({ routerReturnedData }))
        appLogger.init({
          appLogger,
          user: {
            id: get_(wixSdk, ['user', 'currentUser', 'id'])
          },
          inSsr: modeIsSsr,
          viewMode,
          platformBiParams: bi,
          browserUrlGetter: () => get_(wixSdk, ['location', 'url']),
          reportTrace,
          createRavenClient,
          fedOpsLoggerFactory,
          biLoggerFactory
        })

        appLogger.traceSync(traceCreators.initAppForPage(), () => {
          ;({ wixDataProxy, wixDataSchemasProxy } = wixDataProxyCreator(
            appLogger.wixDataCodeZone,
            () =>
              wixDataCreator({
                baseUrl: wixSdk.location.baseUrl,
                envIsEditor: isEnvEditor(viewMode)
              })
          ))
          routerPayload = routerReturnedData || {}
          schemaAPI = schemaAPICreator(wixDataSchemasProxy, appLogger)
          automationsClient = automationsClientCreator(global.elementorySupport)
        })
        return Promise.resolve()
      } catch (e) {
        appLogger.error(e)
        return Promise.reject(e)
      }
    },
    createControllers: rawControllerConfigs => {
      return appLogger.traceSync(traceCreators.createControllers(), () => {
        try {
          if (rawControllerConfigs.length === 0) {
            return []
          }

          const controllerConfigs = completeControllerConfigs(
            rawControllerConfigs
          )
          const viewMode = getViewMode(controllerConfigs)
          const modeIsSSR = isSSRMode(controllerConfigs)

          startLoadingSchemas(controllerConfigs, modeIsSSR)

          const reportFormEventToAutomation = automationsClient.reportFormEventToAutomationCreator(
            {
              isPreview: isEnvEditor(viewMode)
            }
          )

          const datasetTypes = values_(DATASET_TYPES)
          const instansiateDatabindingVerboseReporter = createDatabindingVerboseReporter(
            verboseReporter,
            shouldVerbose
          )
          const renderingControllers = []
          const {
            resolve: renderDeferredControllers,
            promise: renderingRegularControllers
          } = Deferred()

          const controllers = controllerConfigs.map(
            ({
              type,
              config,
              connections,
              $w,
              compId: datasetId,
              livePreviewOptions: {
                shouldFetchData: dataIsInvalidated,
                compsIdsToReset: updatedCompIds = []
              } = {},
              platformAPIs,
              wixCodeApi: wixSdk
            }) => {
              if (!includes_(datasetTypes, type)) {
                throw new Error(
                  `type of controller MUST be one of ${datasetTypes} but is ${type}`
                )
              }

              appLogger.trace(
                TraceType.Breadcrumb({
                  level: 'info',
                  category: 'createControllers',
                  message: 'warmup data contents',
                  data: {
                    datasetId,
                    datasetType: type,
                    env: get_(wixSdk, ['window', 'rendering', 'env']),
                    warmupData: false
                  }
                })
              )

              const routerData =
                type === DATASET_TYPES.ROUTER_DATASET
                  ? extractRouterPayload(routerPayload, convertFromCustomFormat)
                  : undefined

              const recordStoreService = createRecordStoreService({
                primaryDatasetId: datasetId,
                recordStoreCache,
                refreshStoreCache: dataIsInvalidated,
                wixDataProxy,
                warmupStore: undefined,
                controllerConfig: config,
                logger: appLogger
              })

              const {
                promise: renderingController,
                resolve: markControllerAsRendered
              } = Deferred()

              renderingControllers.push(renderingController)

              const controllerFactory = createControllerFactory(appLogger, {
                $w,
                controllerConfig: config,
                datasetType: type,
                connections,
                recordStoreService,
                wixDataProxy,
                firePlatformEvent: appLogger.userCodeZone($w.fireEvent),
                wixSdk,
                errorReporter,
                verboseReporter,
                instansiateDatabindingVerboseReporter,
                routerData,
                appLogger,
                datasetId,
                handshakes: [],
                schemaAPI,
                reportFormEventToAutomation,
                platformAPIs,
                updatedCompIds,
                markControllerAsRendered,
                renderingRegularControllers,
                // isModeLivePreview is ture only if the LivePreview feature is enabled,
                // since in other case the Viewer won't be loaded at all
                modeIsLivePreview: isModeLivePreview(viewMode),
                modeIsSSR
              })

              const datasetController = extractPlatformControllerAPI(
                controllerFactory.createPrimaryController()
              )
              return Promise.resolve(datasetController)
            }
          )

          Promise.all(renderingControllers).then(renderDeferredControllers)

          return controllers
        } catch (e) {
          appLogger.error(e)
          return []
        }
      })
    }
  }

  return app
}
